import { http } from './config'

export default {
    getByCodigo: (codigo) => {
        return http.get('revenda/codigo/' + codigo)
    },
    getClientes: (data) => {
        return http.get('revenda/clientes/', data)
    },
    getOrcamentos: (data, params = null) => {
        params = params ? "?" + params : "/"
        return http.get('revenda/orcamentos' + params, data)
    },
    getSegundaViaBoletos: (codigo = null) => {
        return http.get('revenda/segunda-via/' + codigo);
    },
    getPedidos: (data, params = null) => {
        params = params ? "?" + params : "/"
        return http.get('revenda/pedidos/' + params, data)
    },
    getUrlSegundaViaItau: (data) => {
        data = JSON.stringify(data);
        return http.post('revenda/url-segunda-via/', data)
    },
    getUrlSegundaViaSantander: (data) => {
        return http.get('revenda/url-segunda-via-santander/' + data.cd_filial + '/' + data.cliente_cnpj + '/' + data.NOSSO_NUMERO);
    },
    setLogo: (formData) => {
        const options = {
            headers: { 'Content-Type': 'multipart/form-data' }
        };

        return http.post('revenda/logo',
            formData,
            options
        )
    },
    setMargem: (value) => {
        return http.put('revenda/margem', { value: value })
    },
    getRevendaDb1: () => {
        return http.get('revenda/revenda-db1/')
    },
    atualizarStatusMensagem: (codigo) => {
        return http.put('revenda/atualizar-status-mensagem/' + codigo)
    },
    getStatusPopupUsuario: (codigo) => {
        return http.get('revenda/get-status-mensagem-popup/' + codigo)
    },
    getDataService: () => {
        return http.get("revenda/atendimento");
    },
    getRevendasDaRede: (id) => {
        return http.get("revenda/revendas-rede/" + id);
    }
}
